import React, { Component } from 'react';
import 'moment/locale/id.js';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import {
  map,
  isEmpty
} from 'lodash'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";

const moment = require('moment');

class Pendaftaran extends Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
      listField: [],
      listPdf: {},
      fields: {},
      is_show: false,
      note_verification: '',
    }
  }

  getFiles(files) {
    this.setState({ encrypt_64: files[0].base64, original_name: files[0].name, })
  }

  onChange = (e) => {
    let fields = this.state.fields;
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    fields[e.target.name] = a;
    this.setState({ fields });
  };

  createSelectItems(item) {
    const pdf_table = item.pdf_table;
    const test = "." + pdf_table;
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER"))[pdf_table];

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id} >
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  componentDidMount() {
    this.setState({
      is_show: true,
      student_id: localStorage.getItem('student_id'),
      fields: JSON.parse(localStorage.getItem('STUDENT_DETAIL')),
      listField: JSON.parse(
        localStorage.getItem("DATA_PARAMETER")
      ).prm_ms_student,
      listPdf: JSON.parse(localStorage.getItem("DATA_PARAMETER"))
    });
    // fetch(global.variable.LINK_API + 'student/detail/' + localStorage.getItem('student_id'),
    //   {
    //     method: 'post',
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       'Authorization': 'Bearer ' + global.cookies.get('access_token'),
    //     },
    //     //body: JSON.stringify(data), 
    //   })
    //   .then(response => response.json())
    //   .then(resData => {
    //     if (resData.message === "Success") {
    //       this.setState(resData.data[0]);
    //       if (this.state.note_verification === null) {
    //         this.setState({ note_verification: "" })
    //       }
    //       //this.setState({note_verification : resData.data[0].note_verification});
    //       this.setState({ is_show: true });
    //       console.log(this.state);
    //     }

    //   })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  handleSubmit = event => {
    event.preventDefault();
    //console.log("state 1 " + JSON.stringify(this.state));

    //console.log(this.state.encrypt_64);

    console.log(JSON.stringify(this.state));

  }

  handleBack = () => {
    window.location.href = "/verifikasi";
  }

  download = param => e => {
    e.preventDefault();
    // console.log(param)
    window.open(param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }

  handlePrint = (e) => {
    e.preventDefault();
    window.location.href = "/cetakskfinansial";
  }

  form1() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Nama Siswa</label>
            <input
              className="form-control"
              type="Text"
              name="jalur"
              value={localStorage.getItem('student_id') + '-' + this.state.fullname}
              disabled />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="exampleForm2">Jalur</label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              value={this.state.registration_type.name}
              disabled
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">

            <label htmlFor="exampleForm2">Nama Lengkap</label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              value={this.state.fullname}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Nama Panggilan</label>
            <input
              className="form-control"
              type="Text"
              name="nickname"
              maxLength="100"
              value={this.state.nickname}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Jenis Kelamin</label>
            <input
              className="form-control"
              type="Text"
              name="gender"
              maxLength="100"
              value={this.state.gender.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>NIK</label>
            <input
              className="form-control"
              type="Text"
              name="nik"
              maxLength="50"
              value={this.state.nik}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>NO KK (Nomor Induk Keluarga)</label>
            <input
              className="form-control"
              type="Text"
              name="no_kk"
              value={this.state.no_kk}
              maxLength="25"
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>NISN</label>
            <input
              className="form-control"
              type="Text"
              name="nisn"
              maxLength="50"
              value={this.state.nisn}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Tempat Lahir</label>
            <input
              className="form-control"
              type="Text"
              name="pob"
              maxLength="100"
              value={this.state.pob}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Tanggal Lahir</label>
            <input
              className="form-control"
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={moment(this.state.dob, "YYYY-MM-DD").format("DD-MM-YYYY")}
              disabled
            />
          </div>
        </div>
        {/* <Form.Group controlId="exampleForm.ControlInput1">
            <label>No Akta Kelahiran</label>
            <Form.Control 
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={this.state.birth_certificate}
              disabled />
          </Form.Group> */}
      </div>
    );
  }

  form2() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Agama</label>
            <input
              className="form-control"
              type="Text"
              name="religion"
              maxLength="200"
              value={this.state.religion.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Kewarganegaraan</label>
            <input
              className="form-control"
              type="Text"
              name="citizen"
              maxLength="200"
              value={this.state.citizen.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Alamat</label>
            <input
              className="form-control"
              type="Text"
              name="address"
              maxLength="200"
              value={this.state.address}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <MDBRow>
              <MDBCol>
                <label>RT</label>
                <input
                  className="form-control"
                  type="Text"
                  name="rt"
                  maxLength="5"
                  value={this.state.rt}
                  disabled
                />
              </MDBCol>
              <MDBCol>

                <label>RW</label>
                <input
                  className="form-control"
                  type="Text"
                  name="rw"
                  maxLength="5"
                  value={this.state.rw}
                  disabled
                />
              </MDBCol>
            </MDBRow>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Kelurahan</label>
            <input
              className="form-control"
              type="Text"
              name="sub_district"
              maxLength="100"
              value={this.state.sub_district}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Kecamatan</label>
            <input
              className="form-control"
              type="Text"
              name="district"
              maxLength="100"
              value={this.state.district}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Kabupaten/Kota</label>
            <input
              className="form-control"
              type="Text"
              name="sub_province"
              maxLength="100"
              value={this.state.sub_province}
              disabled
            />
          </div>

          <div className="form-group col-md-6">
            <label>Provinsi</label>
            <input
              className="form-control"
              type="Text"
              name="province"
              maxLength="100"
              value={this.state.province}
              disabled
            />
          </div>
        </div>
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <label>Kodepos</label>
          <Form.Control 
            type="Text"
            name="zipcode"
            maxLength="5"
            value={this.state.zipcode}
            disabled  />
        </Form.Group> */}
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <label>Garis Bujur</label>
          <Form.Control 
            type="Text"
            name="longitude"
            maxLength="5"
            value={this.state.longitude}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Garis Lintang</label>
          <Form.Control 
            type="Text"
            name="latitude"
            maxLength="5"
            value={this.state.latitude}
            disabled  />
        </Form.Group> */}

      </div>

    );
  }

  form3() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Tinggal Dengan</label>
            <input
              className="form-control"
              type="Text"
              name="stay_id"
              maxLength="2"
              value={this.state.stay.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Moda Transportasi</label>
            <input
              className="form-control"
              type="Text"
              name="transportation"
              maxLength="2"
              value={this.state.transportation.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Jarak Tempuh (KM)</label>
            <input
              className="form-control"
              type="Text"
              name="school_distance_hour"
              maxLength="5"
              value={this.state.school_distance_hour}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Waktu Tempuh (Menit)</label>
            <input
              className="form-control"
              type="Text"
              name="school_distance_minute"
              maxLength="5"
              value={this.state.school_distance_minute}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>No. Handphone</label>
            <input
              className="form-control"
              type="Text"
              name="hp"
              format="+62############"
              maxLength="2"
              value={this.state.hp}
              disabled />
          </div>
          <div className="form-group col-md-6">
            <label>Email</label>
            <input
              className="form-control"
              type="Text"
              name="email"
              maxLength="2"
              value={this.state.email}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Anak ke</label>
            <input
              className="form-control"
              type="Text"
              name="child"
              maxLength="2"
              value={this.state.child}
              disabled
            />
          </div>

          <div className="form-group col-md-6">

            <label>Dari bersaudara</label>
            <input
              className="form-control"
              type="Text"
              name="total_children"
              maxLength="2"
              value={this.state.total_children}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>
              Saudara Yang Masih Menjadi Tanggungan Orang Tua ... Orang
            </label>
            <input
              className="form-control"
              type="Text"
              name="sibling_dependent"
              maxLength="2"
              value={this.state.sibling_dependent}
              disabled
            />
          </div>

          {/* <Form.Group controlId="exampleForm.ControlSelect1">
          <label>Golongan Darah</label>
           <input
            className="form-control"
            type="Text"
            name="blood_type"
            maxLength="2"
            value={this.state.blood_type.name}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Tinggi (cm)</label>
           <input
            className="form-control"
            type="Text"
            name="height"
            maxLength="5"
            value={this.state.height}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Berat (kg)</label>
           <input
            className="form-control"
            type="Text"
            name="weight"
            maxLength="5"
            value={this.state.weight}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Lingkar Kepala (cm)</label>
           <input
            className="form-control"
            type="Text"
            name="head_length"
            maxLength="5"
            value={this.state.head_length}
            disabled  />
        </Form.Group> */}
          <div className="form-group col-md-6">
            <label>Sekolah Asal (Sesuai Identitas Raport)</label>
            <input
              className="form-control"
              type="Text"
              name="school_from"
              maxLength="2"
              value={this.state.school_from.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Nama Sekolah Asal (Sesuai Identitas Raport)</label>
            <input
              className="form-control"
              type="Text"
              name="school_from_name"
              maxLength="2"
              value={this.state.school_from_name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Alamat Sekolah Asal (Sesuai Identitas Raport)</label>
            <input
              className="form-control"
              type="Text"
              name="school_from_address"
              maxLength="2"
              value={this.state.school_from_address}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Lama Belajar (Dalam Tahun)</label>
            <input
              className="form-control"
              type="Text"
              name="study_year"
              maxLength="2"
              value={this.state.study_year}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Uang SPP Sekolah Asal</label>
            <CurrencyFormat
              value={this.state.spp}
              style={{ width: "100%" }}
              thousandSeparator={true}
              prefix={"Rp. "}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Daya Listrik</label>
            <input
              className="form-control"
              type="Text"
              name="electrical_power_id"
              maxLength="2"
              value={
                this.state.electrical_power
                  ? this.state.electrical_power.name
                  : ""
              }
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Biaya rata-rata listrik per bulan</label>

            <CurrencyFormat
              value={this.state.bill_pln}
              style={{ width: "100%" }}
              thousandSeparator={true}
              prefix={"Rp. "}
              disabled
            />
          </div>
        </div>

        <div>
          <div className="form-row">
            <div className="form-group col-md-6">

              <label>Keadaan Orang Tua</label>
              <input
                className="form-control"
                type="Text"
                name="parent_status_id"
                maxLength="2"
                value={
                  this.state.parent_status ? this.state.parent_status.name : ""
                }
                disabled
              />
            </div>
            <div className="form-group col-md-6">
              <label>Keadaan Pekerjaan Orang Tua</label>
              <input
                className="form-control"
                type="Text"
                name="both_parent_work"
                maxLength="2"
                value={
                  this.state.both_parent_work
                    ? this.state.both_parent_work.name
                    : ""
                }
                disabled
              />
            </div>
          </div>
        </div>
      </div>

    );
  }

  getMaster(id = 0, table) {
    var array = this.state.listPdf[table];
    var arrayfilter = array.filter((data) => { if (parseInt(data.id) === parseInt(id)) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '-';
    return name;
  }

  form4() {
    let guardian = this.state.fields.guardian;
    console.log(guardian)
    let showitem = [];
    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].nik}>
          <hr />
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Orang Tua{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].guardian_id, 'pdf_guardian')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama Lengkap{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].fullname}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              NIK{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].nik}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Tanggal Lahir{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {moment(guardian[i].dob, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Agama{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].religion_guardian_id, 'pdf_religion')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Status Hubungan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].status_guardian_id !== null ? this.getMaster(guardian[i].status_guardian_id, 'pdf_status_guardian') : guardian[i].status_guardian}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Pendidikan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].education_id, 'pdf_education_guardian')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Pekerjaan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].occupation_id, 'pdf_occupation')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Penghasilan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              <CurrencyFormat
                value={guardian[i].salary}
                displayType={"text"}
                style={{ width: "100%" }}
                thousandSeparator={true}
                prefix={"Rp. "}
                disabled
              />{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              No HP{" "}
            </label>
            <label className="col-md-3 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].hp}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Alamat{" "}
            </label>
            <label className="col-md-3 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].address}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form5() {
    let guardian = this.state.fields.sibling;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Kelas{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].class}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form6() {
    let guardian = this.state.fields.achieve;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Jenis{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].achieve_type_id, 'pdf_achieve_type')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama Prestasi{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Penyelenggara{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].promoter}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Tingkat{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].achieve_rank_id, 'pdf_achieve_rank')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Peringkat{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].rank}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }



  formdokumen() {
    let guardian = this.state.fields.document_upload;
    let showitem = [];
    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div className="form-group col-md-12 pl-0" key={i}>
          <label className="font-weight-bold">{this.getMaster(guardian[i].document_id, 'prm_document_upload')}</label>
          <div>
            {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}

            <Link
              to=""
              target="_blank"
              onClick={this.download(guardian[i].link)}
            >
              Lihat Dokumen
            </Link>
          </div>
        </div>
      );
    }
    return showitem;
  }

  formbukti() {
    return (
      /*<Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_transaction.encrypt_64} thumbnail />*/
      // <div>
      //   <Link target="_blank" onClick={this.download(this.state.fields.image_link_bukti_transfer)}>Lihat Bukti Bayar</Link>

      //   <div className='form-group' controlId="exampleForm.ControlInput1">
      //     <label htmlFor="exampleForm2">Bukti Bayar</label>
      //     <input
      //       type="Text"
      //       name="note"
      //       maxLength="50"
      //       defaultValue={this.state.fields.note_bukti_transfer}
      //       onChange={this.onChange}
      //       disabled />
      //   </div>
      // </div>
      <>
        <hr />
        <div className="form-group col-md-12">
          <div className='row flex-column'>
            <label
              className="font-weight-bold"
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Bukti Bayar
            </label>
            <Link to="" target="_blank" onClick={this.download(this.state.fields.image_link_bukti_transfer)}>Lihat Bukti Bayar</Link>
            <label className='font-weight-bold mt-1'>Catatan</label>
            <input
              className='form-control'
              type="Text"
              name="note"
              maxLength="50"
              defaultValue={this.state.fields.note_bukti_transfer}
              onChange={this.onChange}
              disabled />
          </div>
        </div>
      </>
    );
  }

  handleTest = event => {

    global.swal({
      text: 'Apakah anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id: localStorage.getItem('DATA_JALUR').split('-')[0], flow_id: 4, note_verification: this.state.note_verification }

        return fetch(global.variable.LINK_API + 'flow/update',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: JSON.stringify(data),
          })
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          //console.log(resData);
          let m = moment();
          m = moment(resData.data.schedule_test_at, 'YYYY-MM-DD').format('DD-MM-YYYY');
          global.swal("Success", 'Jadwal test adalah ' + m + '\n Pada pukul 13.00 – 15.00 WIB \nDi ruang ' + resData.data.classroom_id, "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }

      })
      .catch(err => {
        console.log(err)
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });



  }

  handleWawancara = event => {
    global.swal({
      text: 'Apakah anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id: localStorage.getItem('DATA_JALUR').split('-')[0], flow_id: 5, note_verification: this.state.note_verification }

        return fetch(global.variable.LINK_API + 'flow/update',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: JSON.stringify(data),
          })
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          console.log(resData);
          const moment = require('moment');
          let m = moment();
          m = moment(resData.data.schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
          global.swal("Success", 'Jadwal Wawancara adalah ' + m, "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }

      })
      .catch(err => {
        console.log(err)
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });



  }

  handleTolak = event => {
    global.swal({
      text: 'Apakah anda yakin akan ditolak?',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id: localStorage.getItem('DATA_JALUR').split('-')[0], flow_id: 99, note_reject: confirm }
        console.log(JSON.stringify(data))
        return fetch(global.variable.LINK_API + 'flow/update',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: JSON.stringify(data),
          })
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          console.log(resData);

          global.swal("Success", 'Berhasil Tolak', "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }

      })
      .catch(err => {
        console.log(err)
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  }

  handleJPA = event => {

    global.swal({
      text: 'Apakah anda yakin akan dipindah jalur?',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;

        let note_confirmation = 'Apakah anda yakin anak anda akan dipindah ke Jalur Prestasi Akademik?';
        const data = { student_id: localStorage.getItem('DATA_JALUR').split('-')[0], flow_id: 3, registration_type_id: 'JPA', note_change_registration_type: confirm, note_confirmation: note_confirmation }
        console.log(data)

        return fetch(global.variable.LINK_API + 'flow/update',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: JSON.stringify(data),
          })
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          console.log(resData);

          global.swal("Success", 'Berhasil Ubah Jalur', "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }

      })
      .catch(err => {
        console.log(err)
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  }

  handleJT = event => {

    global.swal({
      text: 'Apakah anda yakin akan dipindah jalur?',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;

        let note_confirmation = 'Apakah anda yakin anak anda akan dipindah ke Jalur Test?';
        const data = { student_id: localStorage.getItem('DATA_JALUR').split('-')[0], flow_id: 3, registration_type_id: 'JT', note_change_registration_type: confirm, note_confirmation: note_confirmation }

        return fetch(global.variable.LINK_API + 'flow/update',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: JSON.stringify(data),
          })
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          console.log(resData);

          global.swal("Success", 'Berhasil Ubah Jalur', "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }

      })
      .catch(err => {
        console.log(err)
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });



  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Detail Siswa
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          {map(this.state.listField, (item, idx) => {
                            const field = item.field;
                            return (
                              <React.Fragment key={idx}>
                                <div className="form-group col-md-12">
                                  <label>
                                    {item.name}
                                    {/* {item.is_required == 1 ? (<span className="text-danger"> *</span>) : ""} */}
                                  </label>
                                  {item.type == 1 ? (
                                    <>
                                      {item.type_text == "nominal" ? (
                                        <CurrencyFormat
                                          className="form-control"
                                          input="number"
                                          name={item.field}
                                          onChange={this.onChangeNom}
                                          value={this.state.fields[item.field] || ''}
                                          maxLength="25"
                                          thousandSeparator={true}
                                          disabled
                                          style={{ width: "100%" }}
                                        />
                                      ) : (
                                        <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          maxLength="100"
                                          value={this.state.fields[item.field] || ''}
                                          onChange={this.onChange}
                                          disabled
                                          required={
                                            item.is_required == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <select
                                        id={idx}
                                        className="form-control"
                                        name={item.field}
                                        disabled
                                        onChange={this.onDropdownSelected.bind(
                                          this
                                        )}
                                        value={this.state.fields[item.field] || ''}
                                      >
                                        <option value="">
                                          - Pilih {item.name} -
                                        </option>
                                        {this.createSelectItems(item)}
                                      </select>
                                    </>
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>

                        {
                          this.state.is_show ? (
                            <div>
                              <hr />
                              <label
                                className="font-weight-bold"
                                style={{
                                  fontSize: 20,
                                  textDecorationLine: "underline",
                                }}
                              >
                                Data Orang Tua
                              </label>
                            </div>
                          ) : null
                        }
                        {this.state.is_show && this.state.fields.guardian.length > 0 ? this.form4() : null}
                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Saudara yang masih bersekolah di{" "}
                              {this.state.nama_sekolah}
                            </label>
                          </div>
                        ) : null}

                        {this.state.is_show && this.state.fields.sibling.length > 0 ? this.form5() : null}
                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Prestasi
                            </label>
                          </div>
                        ) : null}

                        {this.state.is_show && this.state.fields.achieve.length > 0 ? this.form6() : null}
                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Dokumen
                            </label>
                          </div>
                        ) : null}

                        {this.state.is_show && this.state.fields.document_upload.length > 0 ? (
                          <>
                            {this.formdokumen()}
                            <hr />
                            <label htmlFor="exampleForm2" className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}>Catatan Dokumen</label>
                            <textarea
                              className="form-control"
                              type="textarea"
                              rows="5"
                              name="note_document_upload"
                              maxLength="100"
                              disabled
                              // fieldname="document"
                              defaultValue={this.state.fields.note_document_upload || ''}
                              onChange={this.onChange}
                            />
                          </>
                        ) : null}

                        {this.state.is_show && this.state.fields.image_link_bukti_transfer ?
                          this.formbukti()
                          : null}
                        {this.state.is_show ? (
                          <>
                            <div>
                              <hr />
                              <label className='font-weight-bold' style={{ fontSize: 20, textDecorationLine: 'underline' }} >Catatan Tentang Pendaftar</label>
                            </div>
                            <div className="form-group">
                              <label className='font-weight-bold'>Catatan</label>
                              <textarea
                                className="form-control"
                                as="textarea" rows="5"
                                name="note_verification"
                                defaultValue={this.state.fields.note_verification}
                                onChange={this.onChange}
                                disabled
                              />
                            </div>
                          </>
                        ) : null}
                        {
                          this.state.fields.flow_id === 'FIN' ?
                            <MDBBtn
                              onClick={this.handlePrint}
                              color="primary"
                              block
                              style={{ margin: 5, height: 50 }}
                            >
                              Lihat Kesepakatan Wawancara
                            </MDBBtn> : ''
                        }
                        {/* <Form.Group controlId="exampleForm.ControlInput1">
                            <label>Nama Siswa</label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('student_id') +'-'+ this.state.fullname}
                              disabled />
                          </Form.Group> */}
                        {/* {this.state.is_show ?
                      this.form1()
                      : ''}

                    {this.state.is_show ?
                      this.form2()
                      : ''}

                    {this.state.is_show ?
                      this.form3()
                      : ''}

                    {this.state.is_show ?
                      <div>
                        <hr />
                        <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Data Orang Tua</label>
                      </div>
                      : ''}

                    {this.state.is_show ?
                      this.form4()
                      : ''}


                    {this.state.is_show ?
                      <div>
                        <hr />
                        <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Saudara yang masih bersekolah di SMA PL Don Bosko</label>
                      </div>
                      : ''}

                    {this.state.is_show ?
                      this.form5()
                      : ''}

                    {this.state.is_show ?
                      <div>
                        <hr />
                        <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Prestasi</label>
                      </div>
                      : ''}

                    {this.state.is_show ?
                      this.form6()
                      : ''}

                    {this.state.is_show ?
                      <div>
                        <hr />
                        <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Nilai Sekolah Asal</label>
                      </div>
                      : ''}

                    {this.state.is_show ?

                      this.state.scores.map((item) => {
                        //console.log( item);
                        let subject = item.subject;
                        return (
                          <div key={item.classroom_id}>
                            <hr />
                            <div className='rowHorizontal' style={{ alignItems: 'center', textAlign: 'center', border: '1px solid black', width: 600 }}>
                              <label className="col-md-12" style={{ width: 200, margin: 5, fontSize: 20 }} >{item.classroom_name} </label>
                              <label className="col-md-3" style={{ width: 200, margin: 5, borderLeft: '1px solid black' }}>Semester 1</label>
                              <label className="col-md-3" style={{ width: 200, margin: 5, borderLeft: '1px solid black' }}>Semester 2</label>
                            </div>
                            <hr />
                            {subject.map((item1) => {
                              //console.log(item1);
                              let semester = item1.semester;
                              return (
                                <div className='form-group' controlId="exampleForm.ControlInput1" key={item.classroom_id + "_" + item1.subject_id}>
                                  <div className='rowHorizontal' style={{ alignItems: 'center', textAlign: 'center', border: '1px solid black', width: 600 }}>
                                    <label className="col-md-3" style={{ width: 200, margin: 5 }}>{item1.subject_name} </label>
                                    {semester.map((item2) => {
                                      return (
                                        <label className="col-md-3" style={{ width: 200, margin: 5, borderLeft: '1px solid black' }}>{item2.score} </label>
                                      )
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                            <hr />
                          </div>
                        );
                      })
                      : ''}

                    {this.state.is_show ?
                      <div>

                        <div className='rowHorizontal' style={{ alignItems: 'center', textAlign: 'center', border: '1px solid black', width: 600 }}>
                          <label className="col-md-3" style={{ width: 200, margin: 5 }}>Total Nilai</label>
                          <label className="col-md-6" style={{ width: 200, margin: 5, borderLeft: '1px solid black' }}>{this.state.totalScore} </label>
                        </div>
                        <div className='rowHorizontal' style={{ alignItems: 'center', textAlign: 'center', border: '1px solid black', marginTop: 5, width: 600 }}>
                          <label className="col-md-3" style={{ width: 200, margin: 5 }}>Rata - Rata Nilai</label>
                          <label className="col-md-6" style={{ width: 200, margin: 5, borderLeft: '1px solid black' }}>{this.state.totalAvg} </label>
                        </div>
                      </div>
                      : ''}

                    {this.state.upload_documents ?
                      <div>
                        {this.state.is_show ?
                          <div>
                            <hr />
                            <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Dokumen</label>
                          </div>
                          : ''}

                        {this.state.is_show ?
                          this.formdokumen()
                          : ''}

                        {this.state.is_show ?
                          <div>
                            <hr />
                            <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Bukti Bayar</label>
                          </div>
                          : ''}

                        {this.state.is_show && this.state.upload_transaction ?
                          this.formbukti()
                          : ''}

                        <div>
                          <hr />
                          <label style={{ fontSize: 20, textDecorationLine: 'underline' }} >Catatan Tentang Pendaftar</label>
                        </div>
                        <div className="form-group">
                          <label>Catatan</label>
                          <textarea
                            className="form-control"
                            as="textarea" rows="5"
                            name="note_verification"
                            value={this.state.note_verification}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      : ''} */}
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default Pendaftaran;